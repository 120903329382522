<template>

  <Toast/>
  <div class="card">
    <Steps :model="items" :readonly="true"/>
  </div>

  <Card style="border-radius:12px;">
    <template v-slot:title>
      Cadena de Comercialización
    </template>
    <template v-slot:subtitle>
      Añadir distribuidor
    </template>
    <template #content>
      <div class="filters">
        <div class="field">
          <!-- Comercializador -->
          <Dropdown v-model="selectedComercializador" :options="comercializadores"
                    optionLabel="tcomercializador" optionValue="id_comercializador"
                    placeholder="Seleccionar Bloque" class="filterItem" :loading="loadingComercializador"
                    :showClear="true"
                    v-if="Number(identity.roleId) <= 1"
                    style="width: 100%;"
                    :filter="true"/>
          <br>
          <!-- Banca -->
          <Dropdown v-model="selectedBanca" :options="bancas"
                    optionLabel="tbanca" optionValue="id_banca"
                    placeholder="Seleccionar Banca" class="filterItem" :loading="loadingBanca"
                    :showClear="true"
                    :onClick="refreshBanca"
                    v-if="Number(identity.roleId) <= 2 && selectedComercializador"
                    style="width: 100%"
                    :filter="true"/>
          <br>
        </div>
      </div>

    </template>
    <template #footer>
      <div class="grid grid-nogutter justify-content-between">
        <Button label="Anterior" @click="prevPage()" icon="pi pi-angle-left"/>
        <Button label="Siguiente"
                @click="nextPage()"
                icon="pi pi-angle-right"
                iconPos="right"
                :disabled="nextDisabled()"
        />
      </div>
    </template>
  </Card>

  <router-view v-slot="{Component}"
               @prevPage="prevPage($event)"
               @nextPage="nextPage($event)">
    <keep-alive>
      <component :is="Component"/>
    </keep-alive>
  </router-view>

</template>

<script>
import MainService from "@/service/backend/MainService";
import generalMixin from "@/mixins/generalMixin";
import {useVuelidate} from "@vuelidate/core";

export default {
  name: "Step2",
  mixins: [generalMixin],
  setup: () => ({v$: useVuelidate()}),
  created() {
    this.mainService = new MainService();
    this.identity = {
      roleId: this.$store.getters.getProfile.role_id,
      entityId: this.$store.getters.getProfile.entity_id,
    };
    this.theDate = this.getYesterdayString();
    this.$primevue.config.locale.emptyMessage = "Buscando opciones...";

  },
  mounted() {
    this.distribuidores = this.refreshDistribuidor();
    this.bancas = this.refreshBanca();
    this.comercializadores = this.refreshComercializador();

    this.selectedDistribuidor = this.$store.getters.getDistributor.id_distribuidor;

    // It's a block
    if (Number(this.identity.roleId) <= 2) {
      this.selectedComercializador = this.identity.entityId;
    }
    // It's a banking
    if (Number(this.identity.roleId) <= 3) {
      this.selectedComercializador = this.$store.getters.getProfile.id_comercializador;
      this.selectedBanca = this.$store.getters.getProfile.id_banca;
      this.selectedDistribuidor = this.identity.entityId;
    }
    // It's a distributor
    if (Number(this.identity.roleId) <= 4) {
      this.selectedComercializador = this.$store.getters.getProfile.id_comercializador;
      this.selectedBanca = this.$store.getters.getProfile.id_banca;
      this.selectedDistribuidor = this.$store.getters.getProfile.id_distribuidor;
    }

  },
  data() {
    return {
      items: [
        {
          label: 'Nombre',
          to: '/distribuidores/agregar-1'
        },
        {
          label: 'Cadena de comercialización',
          to: '/distribuidores/agregar-2'
        },
        {
          label: 'Dirección',
          to: '/distribuidores/agregar-3'
        },
      ],

      // Comercializador
      selectedComercializador: null,
      comercializadores: null,
      loadingComercializador: false,
      // Banca
      selectedBanca: null,
      bancas: null,
      loadingBanca: false,
      // Distribuidor
      selectedDistribuidor: null,
      distribuidores: null,
      loadingDistribuidor: false,

      pageIndex: 0,
      roleId: null,
      params: null,
    }
  },
  methods: {
    async refreshComercializador() {
      this.loading = true;
      this.selectedComercializador = null;
      this.comercializadores = await this.mainService.getComercializador({
        fecha: this.theDate,
        identity: this.identity,
        id_comercializador: this.selectedComercializador,
        page: 'agencies',
        hide_external: true,
      });
      this.loading = false;
    },
    async refreshBanca() {
      this.loading = true;
      this.selectedBanca = null;
      this.bancas = await this.mainService.getBanca({
        fecha: this.theDate,
        identity: this.identity,
        id_comercializador: this.selectedComercializador,
        hide_external: true,
        page: 'agencies',
      });
      this.loading = false;
    },
    async refreshDistribuidor() {
      this.loadingDistribuidor = true;
      this.selectedDistribuidor = null;
      this.distribuidores = await this.mainService.getDistribuidor({
        fecha: this.theDate,
        identity: this.identity,
        id_comercializador: this.selectedComercializador,
        id_banca: this.selectedBanca,
        page: 'agencies',
      });
      this.loadingDistribuidor = false;
    },
    nextPage() {
      this.$store.commit('setDistributor', {
        id_comercializador: this.selectedComercializador,
        id_banca: this.selectedBanca,
        distributorName: this.$store.getters.getDistributor.distributorName,
        id_estado: this.$store.getters.getDistributor.id_estado,
        id_municipio: this.$store.getters.getDistributor.id_municipio,
      });
      this.$router.push({name: 'distribuidores/agregar-3',});
    },
    prevPage() {
      this.$store.commit('setDistributor', {
        id_comercializador: this.selectedComercializador,
        id_banca: this.selectedBanca,
        distributorName: this.$store.getters.getDistributor.distributorName,
        id_estado: this.$store.getters.getDistributor.id_estado,
        id_municipio: this.$store.getters.getDistributor.id_municipio,
      });
      this.$router.push('/distribuidores/agregar-1');
    },
    nextDisabled() {
      let response = !this.selectedBanca;
      if (this.$store.getters.getProfile.role_id === 3) {
        response = false;
      }
      return response;
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep(b) {
  display: block;
}

::v-deep(.p-card-body) {
  padding: 2rem;
}

.filters {
  margin-top: 10px;
}

.filterItem {
  margin: 20px 0 5px 0;
}

</style>